import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { CustomerModel, DocumentApproveModel } from '../models/auth.model';
import { NavController, Platform } from '@ionic/angular';
import { Token } from '@capacitor/push-notifications';
import { HesapService, JobNotificationService, JwtModel, PhoneTokenModel, PhoneTokenType } from '@bob/api';
import { AlertService, LanguageService, StorageService } from '@bob/service';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';

export type authStatus = 'true' | 'false' | 'register';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // public isAuthenticated: BehaviorSubject<authStatus | null> = new BehaviorSubject<authStatus | null>('false');
  private customer: CustomerModel | undefined;
  private decodedToken?: JwtModel;
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  private _customerBehavior = new BehaviorSubject<CustomerModel | null>(null);
  public customer$ = this._customerBehavior.asObservable();

  private _navCtrl = inject(NavController);
  private _platform = inject(Platform);
  private _hesapService = inject(HesapService);
  private _jobNotificationService = inject(JobNotificationService);
  private _storageService = inject(StorageService);
  private _alertService = inject(AlertService);
  private _translate = inject(TranslateService);
  private _lang = inject(LanguageService);

  constructor() {
    // this.customer$.subscribe(data => console.log(data));
  }

  private async setAccessToken(token: string | null): Promise<void> {
    if (token) {
      await this._storageService.set({
        key: 'access_token',
        value: token
      });
    }
  }

  private async getAccessToken(): Promise<string | null> {
    const result = await this._storageService.get({
      key: 'access_token'
    });
    return result?.value ?? null;
  }

  private async setRefreshToken(token: string | null): Promise<void> {
    if (token) {
      await this._storageService.set({
        key: 'refreshToken',
        value: token
      });
    }
  }

  public async getCustomer(): Promise<CustomerModel | null> {
    const result = await this._storageService.get({
      key: 'customer'
    });

    if (!(result && result.value)) return null;

    const user = JSON.parse(result.value ?? '');
    this._customerBehavior.next(user);
    return user ?? null;
  }

  public async setCustomer(customer: CustomerModel | null): Promise<void> {
    if (customer) {
      await this._storageService.set({
        key: 'customer',
        value: JSON.stringify(customer)
      });
      this._customerBehavior.next(customer);
    }
  }

  private async getRefreshToken(): Promise<string | null> {
    const result = await this._storageService.get({
      key: 'refreshToken'
    });
    return result?.value ?? null;
  }

  public async revokeToken(): Promise<string | null> {
    if (this.refreshTokenInProgress) {
      return lastValueFrom(
        await this.refreshTokenSubject.asObservable().pipe(
          filter(token => token !== null),
          take(1)
        )
      );
    }

    this.refreshTokenInProgress = true;
    this.refreshTokenSubject.next(null);

    try {
      let refreshToken = await this.getRefreshToken();

      if (!refreshToken || !(await this.getAccessToken())) {
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject.next(null);
        return null;
      }

      const newToken = await lastValueFrom(this._hesapService.refreshToken(refreshToken));

      if (!newToken?.access_token || !newToken?.refreshToken) {
        this.refreshTokenInProgress = false;
        this.refreshTokenSubject.next(null);
        return null;
      }

      this.setAccessToken(newToken.access_token);
      this.setRefreshToken(newToken.refreshToken);

      this.decodedToken = await this.getDecodedToken();

      this.refreshTokenSubject.next(newToken.access_token);
      this.refreshTokenInProgress = false;

      return newToken.access_token;
    } catch (error) {
      this.refreshTokenInProgress = false;
      this.refreshTokenSubject.next(null);
      return null;
    }
  }

  public async isAuthenticated(): Promise<authStatus> {
    const token = await this.getDecodedToken();
    if (!token) {
      return 'false';
    }
    if (!token.name) {
      this._navCtrl.navigateRoot('/registration');
      return 'register';
    }

    return 'true';
  }

  public login(telNo: string, pass: string, countryCode: string): Promise<authStatus> {
    return new Promise<authStatus>(resolve => {
      if (!pass) {
        this._alertService.presentToast(this._translate.instant('alert.invalid_pass'));
        resolve('false');
        return;
      }

      this._hesapService.token(telNo, pass).subscribe(async response => {
        if (response) {
          if (response.access_token) {
            await this.setAccessToken(response.access_token);
          }
          if (response.refreshToken) {
            await this.setRefreshToken(response.refreshToken);
          }

          this.decodedToken = await this.getDecodedToken();
          console.log(this.decodedToken);

          if (!this.decodedToken) {
            resolve('false');
            return;
          }

          let docApprove: DocumentApproveModel = { gam: this.decodedToken.gam, rp: this.decodedToken.rp };
          await this._storageService.set({
            key: 'doc_approve_model',
            value: JSON.stringify(docApprove)
          });

          let user: CustomerModel = {
            musteriId: +(this.decodedToken.eskiMusteriId ?? 0),
            ad: '',
            status: true,
            telefon: telNo,
            userId: 0
          };

          await this.setCustomer(user);
          this._customerBehavior.next(user);
          this.customer = user;

          if (this.decodedToken.birey) {
            await this.getUserDetailFromOldApi(user);
          }

          return resolve(this.isAuthenticated());
        }
      });
    });
  }

  // private async refreshToken_Sil(): Promise<string | null> {
  //   let refreshToken = await this.getRefreshToken();

  //   if (!refreshToken) {
  //     return null;
  //   }

  //   let accessToken = await this.getAccessToken();

  //   if (!accessToken) {
  //     return null;
  //   }

  //   let newToken = await lastValueFrom(this._hesapService.refreshToken(refreshToken));

  //   if (!newToken || !newToken.access_token || !newToken.refreshToken) {
  //     return null;
  //   }

  //   this.customer = await this.getCustomer();
  //   if (!this.customer) {
  //     return null;
  //   }

  //   await this.setAccessToken(newToken.access_token);
  //   await this.setRefreshToken(newToken.refreshToken);
  //   this.decodedToken = await this.getDecodedToken();
  //   if (!this.decodedToken) {
  //     return null;
  //   }
  //   if (this.decodedToken.birey) {
  //     await this.getUserDetailFromOldApi('', this.customer.password);
  //   }
  //   return newToken.access_token;
  // }

  public async logout(): Promise<void> {
    console.log('logout');

    let tel = await this._storageService.get({ key: 'bobv_telNo' });

    localStorage.clear();
    await this._storageService.clear();
    this.customer = undefined;

    console.log('logout', tel.value);

    this._storageService.set({ key: 'bobv_telNo', value: tel.value });

    if (this._customerBehavior.value) this._customerBehavior.next(null);

    this._navCtrl.navigateRoot('home');
  }

  public async saveFCMToken(token: Token): Promise<void> {
    // ⚡️  [log] - ["ipad","ios","tablet","cordova","capacitor","mobile","hybrid"] for ipad
    // ⚡️  [log] - ["iphone","ios","cordova","capacitor","mobile","hybrid"] for iphone

    const isAuth = await this.isAuthenticated();
    if (isAuth !== 'true') {
      setTimeout(() => this.saveFCMToken(token), 30000); //wait 30secs before checking again.
      return;
    }

    if (!this.customer) {
      setTimeout(() => this.saveFCMToken(token), 30000); //wait 30secs before checking again.
      return;
    }

    let isMobile = true;
    const platforms = this._platform.platforms();
    if (platforms.find(x => x === 'ipad') !== undefined || platforms.find(x => x === 'tablet') !== undefined) {
      isMobile = false;
    }

    let platform = PhoneTokenType.web; // Default olarak Web'i seçiyoruz

    const isPlatformIos = platforms.find(x => ['iphone', 'ios', 'ipad'].includes(x));
    const isPlatformAndroid = platforms.find(x => ['android'].includes(x));

    if (isPlatformIos !== undefined) {
      platform = PhoneTokenType.ios;
    } else if (isPlatformAndroid !== undefined) {
      platform = PhoneTokenType.android;
    }

    const params = new PhoneTokenModel();
    params.token = token.value;
    params.isPhone = isMobile;
    params.tipi = platform;

    // this.httpn.setDataSerializer('json')
    const response = await this._jobNotificationService.savePhoneToken(params).toPromise();

    if (response) {
      this.customer.fcmToken = token.value;
      await this._storageService.set({ key: 'fcmToken', value: token.value });
    } else {
      setTimeout(() => this.saveFCMToken(token), 30000); //wait 30secs before checking again.
    }
  }

  private deletePhoneToken(token: PhoneTokenModel): Observable<boolean> {
    return this._jobNotificationService.deletePhoneToken(token);
  }

  public sendPasswordNew(phone: string): Observable<boolean> {
    return this._jobNotificationService.sendPasswordNew(phone);
  }

  private async getUserDetailFromOldApi(user: CustomerModel): Promise<void> {
    const res = await this._jobNotificationService.getUserDetail();
    if (res && res.status) {
      user = JSON.parse(JSON.stringify(res));
    } else {
      if (this._lang.selected === 'en') {
        this._alertService.presentToast(res.messageEn);
      } else {
        this._alertService.presentToast(res.message);
      }
    }

    await this.setCustomer(user);
    this._customerBehavior.next(user);
    this.customer = user;
    let ret = !(user.ad && user.email);

    if (ret) {
      this._navCtrl.navigateRoot('/registration');
    }

    const url = await this._storageService.get({ key: 'loginReturnUrl' });
    if (url.value) {
      this._navCtrl.navigateRoot(url.value);
      await this._storageService.remove({ key: 'loginReturnUrl' });
    }
  }

  private async getDecodedToken(): Promise<JwtModel | undefined> {
    let token = await this.getAccessToken();
    if (!token) return undefined;
    if (token) return jwt_decode<JwtModel>(token ?? '');
    return new JwtModel();
  }

  private async isTokenExpired(): Promise<boolean> {
    // Get the expiration date
    const date = await this.getTokenExpirationDate();

    if (!date) {
      return true;
    }

    // Check if the token is expired
    return !(date.valueOf() > new Date().valueOf() + 30000);
  }

  private async getTokenExpirationDate(): Promise<Date | undefined> {
    const decodedToken = await this.getDecodedToken();
    if (!decodedToken) return undefined;
    if (!decodedToken.hasOwnProperty('exp')) {
      return undefined;
    }

    // Convert the expiration date
    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp ?? 0);

    return date;
  }

  public async getValidToken(): Promise<string | null> {
    let tokenx = await this.getAccessToken();
    if (tokenx) {
      const isExpired = await this.isTokenExpired();
      if (isExpired) {
        tokenx = await this.revokeToken();
      }
    }

    return tokenx;
  }
}
