import { CardModel } from '@bob/api';
import { CariDetayModel } from 'libs/api/src/lib/models/satis.types';

export interface CustomerModel {
  ad?: string;
  cinsiyet?: number;
  dogumTarihi?: Date;
  email?: string;
  musteriId: number;
  plaka?: string;
  soyad?: string;
  status?: boolean;
  telefon: string;
  userId: number;
  password?: string;
  ccards?: CardModel[];
  defaultCcard?: CardModel;
  cardUserKey?: string;
  walletBalance?: number;
  qrCode?: string;
  invoices?: CariDetayModel[];
  defaultInvoice?: CariDetayModel | null;
  isWalletDefault?: boolean;
  noLoginInvoiceSaved?: boolean;
  profilePic?: string;
  fcmToken?: any;
}

export interface DocumentApproveModel {
  gam: string | undefined;
  rp: string | undefined;
}
