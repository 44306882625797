export class IyzicoPaymentModel {
  public cardno: string;
  public expiry: string;
  public cvc2: string;
  public amount: number;
  public discountamount: number;
  public actualamount: number;
  public gecelikTutar: number;
  public indirimTutariGecelik: number;
  public cardholdername: string;
  public plate: string;
  public rememberCard: boolean;
  public cardAlias: string;
  public cardToken: string;
  public cardUserKey: string | undefined;
  public invoiceId: number;
  public serviceId: string;
  public paymentType: string;
  public basketType: string;
  public doorId: number;
  public advantageId: number;
  public autoReload: boolean;
  public promotionCode: string;

  constructor(item?: any) {
    this.cardno = item?.cardno || undefined;
    this.expiry = item?.expiry || undefined;
    this.cvc2 = item?.cvc2 || undefined;
    this.amount = item?.amount || 0;
    this.discountamount = item?.discountamount || undefined;
    this.actualamount = item?.actualamount || 0;
    this.gecelikTutar = item?.gecelikTutar || undefined;
    this.indirimTutariGecelik = item?.indirimTutariGecelik || undefined;
    this.cardholdername = item?.cardholdername || undefined;
    this.plate = item?.plate || undefined;
    this.rememberCard = item?.rememberCard || undefined;
    this.cardAlias = item?.cardAlias || undefined;
    this.cardToken = item?.cardToken || undefined;
    this.cardUserKey = item?.cardUserKey || undefined;
    this.invoiceId = item?.invoiceId || undefined;
    this.serviceId = item?.serviceId || undefined;
    this.paymentType = item?.paymentType || undefined;
    this.basketType = item?.basketType || undefined;
    this.doorId = item?.doorId || undefined;
    this.advantageId = item?.advantageId || undefined;
    this.autoReload = item?.autoReload || undefined;
    this.promotionCode = item?.promotionCode || undefined;
  }
}

export class IyzicoPaymentResponseModel {
  public html: string;
  public basketId: string;
  public endurl: string;
  public status: boolean;
  public couponUsedAmount: number;

  constructor(item?: any) {
    this.basketId = item?.basketId || undefined;
    this.couponUsedAmount = item?.couponUsedAmount || undefined;
    this.endurl = item?.endurl || undefined;
    this.html = item?.html || undefined;
    this.status = item?.status || undefined;
  }
}

export class SavedCreditCardsReq {
  public cardUserKey: string;

  constructor(item: any) {
    this.cardUserKey = item.cardUserKey || undefined;
  }
}

export class RequestSavedCreditCardsResponse {
  public cards: CardModel[];
  public isWalletDefault: boolean;

  constructor(item?: any) {
    this.cards = item.cards || [];
    this.isWalletDefault = item.isWalletDefault || undefined;
  }
}

export class CardModel {
  public cardalias: string;
  public cardtoken: string;
  public cardType: string;
  public binnumber: string;
  public background: string;
  public backgroundColor: string;
  public bank: string;
  public bankLogo: string;
  public carduserkey: string;
  public isDefault: boolean;
  public balance: number;

  constructor(item?: any) {
    this.cardalias = item?.cardalias || '';
    this.cardtoken = item?.cardtoken || '';
    this.cardType = item?.cardType || '';
    this.binnumber = item?.binnumber || '';
    this.background = item?.background || '';
    this.backgroundColor = item?.backgroundColor || '';
    this.bank = item?.bank || '';
    this.bankLogo = item?.bankLogo || '';
    this.carduserkey = item?.carduserkey || '';
    this.isDefault = item?.isDefault || false;
    this.balance = item?.balance || 0;
  }
}

export class JobsModel {
  public ad: string;
  public aracCagirmaTarihi: Date;
  public anketDurum: string;
  public aracGetirSure: string;
  public aracLogo: string;
  public aracMarka: string;
  public aracModel: string;
  public aracRenk: string;
  public aracRenk_en: string;
  public aracTeslimKapi: string;
  public aracTip: string;
  public aracTip_en: string;
  public avantajlar: string;
  public currency: string;
  public driverSurveyPoint: number;
  public fatura: JobsFaturaModel;
  public fromAddress: string;
  public gecelikTutar: number;
  public hizmetId: string;
  public hizmetIstasyonlar: IstasyonModel[];
  public hizmetRezervasyonId: string;
  public hizmetTipId: number;
  public hizmetTipiAciklama: string;
  public hizmetYer: string;
  public hizmetYerId: number;
  public hizmetDurumId: number;
  public indirimTutari: number;
  public isCancel: boolean;
  public kullanilanAvantaj: string;
  public kullanilanAvantajEn: string;
  public musteriId: number;
  public odemeToplam: number;
  public odenenTutar: number;
  public passengerCount: number;
  public plaka: string;
  public pnr: string;
  public recordStatus: string;
  public recordStatusEn: string;
  public reservationDate: string;
  public reservationId: number;
  public reservationNo: string;
  public serviceName: string;
  public soyad: string;
  public tarih: string;
  public telefon: string;
  public toAddress: string;
  public totalCost: number;
  public transferHizmetTipId: number;
  public tutar: number;
  public yikamaTutari: number;
  public showRating: boolean;
  public cikis_tarihi_gerceklesen: string;
  public cikis_tarihi_musteri_bildirimi: string;
  public giris_tarihi_gerceklesen: string;
  public giris_tarihi_musteri_bildirimi: string;
  public teslimIstasyon: IstasyonModel;
  public odemeDurumu: boolean;
  public odenecekTutar: number;
  public selectedPromo: any;
  public payablePrice: number;
  public price: number;
  public promo: PromoDetailModel;
  public selectedPayment: CardModel;

  constructor(item: any) {
    this.ad = item.ad || '';
    this.aracCagirmaTarihi = item.aracCagirmaTarihi || '';
    this.anketDurum = item?.anketDurum || undefined;
    this.aracGetirSure = item?.aracGetirSure || undefined;
    this.aracLogo = item?.aracLogo || undefined;
    this.aracMarka = item?.aracMarka || undefined;
    this.aracModel = item?.aracModel || undefined;
    this.aracRenk = item?.aracRenk || undefined;
    this.aracRenk_en = item?.aracRenk_en || undefined;
    this.aracTeslimKapi = item?.aracTeslimKapi || undefined;
    this.aracTip = item?.aracTip || undefined;
    this.aracTip_en = item?.aracTip_en || undefined;
    this.avantajlar = item?.avantajlar || undefined;
    this.currency = item?.currency || undefined;
    this.driverSurveyPoint = item?.driverSurveyPoint || undefined;
    this.fatura = item?.fatura || undefined;
    this.fromAddress = item?.fromAddress || undefined;
    this.gecelikTutar = item?.gecelikTutar || undefined;
    this.hizmetId = item?.hizmetId || undefined;
    this.hizmetIstasyonlar = item?.hizmetIstasyonlar || [];
    this.hizmetRezervasyonId = item?.hizmetRezervasyonId || undefined;
    this.hizmetTipId = item?.hizmetTipId || undefined;
    this.hizmetTipiAciklama = item?.hizmetTipiAciklama || undefined;
    this.hizmetYer = item?.hizmetYer || undefined;
    this.hizmetYerId = item?.hizmetYerId || undefined;
    this.hizmetDurumId = item?.hizmetDurumId || undefined;
    this.indirimTutari = item?.indirimTutari || undefined;
    this.isCancel = item?.isCancel || undefined;
    this.kullanilanAvantaj = item?.kullanilanAvantaj || undefined;
    this.kullanilanAvantajEn = item?.kullanilanAvantajEn || undefined;
    this.musteriId = item?.musteriId || undefined;
    this.odemeToplam = item?.odemeToplam || undefined;
    this.odenenTutar = item?.odenenTutar || undefined;
    this.passengerCount = item?.passengerCount || undefined;
    this.plaka = item?.plaka || undefined;
    this.pnr = item?.pnr || undefined;
    this.recordStatus = item?.recordStatus || undefined;
    this.recordStatusEn = item?.recordStatusEn || undefined;
    this.reservationDate = item?.reservationDate || undefined;
    this.reservationId = item?.reservationId || undefined;
    this.reservationNo = item?.reservationNo || undefined;
    this.serviceName = item?.serviceName || undefined;
    this.soyad = item?.soyad || undefined;
    this.tarih = item?.tarih || undefined;
    this.telefon = item?.telefon || undefined;
    this.toAddress = item?.toAddress || undefined;
    this.totalCost = item?.totalCost || undefined;
    this.transferHizmetTipId = item?.transferHizmetTipId || undefined;
    this.tutar = item?.tutar || undefined;
    this.yikamaTutari = item?.yikamaTutari || undefined;
    this.showRating = item?.showRating || undefined;
    this.cikis_tarihi_gerceklesen = item?.cikis_tarihi_gerceklesen || undefined;
    this.cikis_tarihi_musteri_bildirimi = item?.cikis_tarihi_musteri_bildirimi || undefined;
    this.giris_tarihi_gerceklesen = item?.giris_tarihi_gerceklesen || undefined;
    this.giris_tarihi_musteri_bildirimi = item?.giris_tarihi_musteri_bildirimi || undefined;
    this.teslimIstasyon = item?.teslimIstasyon || undefined;
    this.odemeDurumu = item?.odemeDurumu || false;
    this.odenecekTutar = item?.odenecekTutar || undefined;
    this.selectedPromo = item?.selectedPromo || undefined;
    this.payablePrice = item?.payablePrice || undefined;
    this.price = item?.price || undefined;
    this.promo = item?.promo || undefined;
    this.selectedPayment = item?.selectedPayment || new CardModel({});
  }
}

export class IstasyonModel {
  public ad: string;
  public id: number;

  constructor(item: any) {
    this.ad = item.ad || '';
    this.id = item.id || 0;
  }
}

export class PromoDetailModel {
  public promoCode: string;

  constructor(item?: any) {
    this.promoCode = item.promoCode || undefined;
  }
}

export interface hizmetlerModel {
  aracLogo: string;
  driverSurveyPoint: number;
  hizmetId: string;
  hizmetIstasyonlar: IstasyonModel[];
  hizmetRezervasyonId: string;
  hizmetTipId: number;
  hizmetYer: string;
  isCancel: boolean;
  plaka: string;
  recordStatus: string;
  recordStatusEn: string;
  reservationDate: string;
  reservationId: number;
  reservationNo: string;
  tarih: string;
  transferHizmetTipId: number;
  showRating: boolean;
  cikisTarihiGerceklesen: string;
  cikisTarihiMusteriBildirimi: string;
  girisTarihiGerceklesen: string;
  girisTarihiMusteriBildirimi: string;

  pnr: string;
  aracModel: string;
  aracTip: string;
  aracTipEn: string;
  aracRenk: string;
  aracRenkEn: string;
  fromAddress: string;
  toAddress: string;
  aracTeslimKapi: string;
  kullanilanAvantaj: string;
  kullanilanAvantajEn: string;
  indirimTutari: number;
  odenenTutar: number;
  tutar: number;
  totalCost: number;
  fatura: JobsFaturaModel;
}

export class JobsFaturaModel {
  public faturaTipi: string;
  public tcKimlikNoVergiNo: string;
  public adres: string;
  public ilce: string;
  public il: string;

  constructor(item?: any) {
    this.faturaTipi = item?.faturaTipi || undefined;
    this.tcKimlikNoVergiNo = item?.tcKimlikNoVergiNo || undefined;
    this.adres = item?.adres || undefined;
    this.ilce = item?.ilce || undefined;
    this.il = item?.il || undefined;
  }
}

export class PromoJobModel {
  public title: string;
  public id: number;
  public code: string;
  public transactionId: string;
  public ad: string;
  public ad_en: string;
  public promoCode: string;

  constructor(item: any) {
    this.title = item.title || '';
    this.id = item.id || 0;
    this.code = item.code || '';
    this.transactionId = item.transactionId || '';
    this.ad = item.ad || '';
    this.ad_en = item.ad_en || '';
    this.promoCode = item.promoCode || '';
  }
}

export interface UserKeyRespModel {
  key: string;
}

export class AdvantageModelOther {
  public telefon: string | undefined;
  public prm: string | undefined;
  public amount: number | undefined;
  public musteriId: number | undefined;
  public hizmetId: number | undefined;
  public hizmetYerId: number | undefined;
  public advantageId: number | undefined;
  public kartId: number | undefined;
}

export class AdvantageModel {
  public telefon: string | undefined;
  public prm: string | undefined;
  public hizmetId: string | undefined;
  public advantageId: number | undefined;
  public kartId: number | undefined;
}

export class AdvantageResult {
  public status: boolean | undefined;
  public amount: number | undefined;
  public message: string | undefined;
  public message_en: string | undefined;
}
export class CarSelectModel {
  public marka: string;
  public model: string;
  public renk: string;
  public logo: string;
  public hizmetYerId: number;
  public hizmetYerAd: string;
  public plaka: string;
  public istasyonAdi: string;
  public aracTipi: string;
  public istasyonSecenekleri: IstasyonModel[];
  public error: string;
  public sure: string;
  public hizmetDurumId: number;
  public hizmetId: string;

  constructor(item: any) {
    this.marka = item.marka || '';
    this.model = item.model || '';
    this.renk = item.renk || '';
    this.logo = item.logo || '';
    this.hizmetYerId = item.hizmetYerId || null;
    this.hizmetYerAd = item.hizmetYerAd || null;
    this.plaka = item.plaka || '';
    this.istasyonAdi = item.istasyonAdi || '';
    this.logo = item.logo || '';
    this.aracTipi = item.aracTipi || '';
    this.istasyonSecenekleri = item.istasyonSecenekleri || [];
    this.error = item.error || '';
    this.sure = item.aracGetirSure || '';
    this.hizmetDurumId = item.hizmetDurumId || 0;
    this.hizmetId = item.hizmetId || '';
  }
}

export class GetVehicleListModel {
  public aracId: number;
  public aracLogo: string;
  public aracMarka: string;
  public aracModel: string;
  public aracRenk: string;
  public aracRenk_en: string;
  public aracTip: string;
  public aracTip_en: string;
  public markaId: number;
  public modelId: number;
  public musteriId: number;
  public plaka: string;
  public renkId: number;
  public job: JobsModel;
  public activeJob: boolean;

  constructor(item: any) {
    this.aracId = item.aracId || 0;
    this.aracLogo = item.aracLogo || '';
    this.aracMarka = item.aracMarka || '';
    this.aracModel = item.aracModel || '';
    this.aracRenk = item.aracRenk || '';
    this.aracRenk_en = item.aracRenk_en || '';
    this.aracTip = item.aracTip || '';
    this.aracTip_en = item.aracTip_en || '';
    this.markaId = item.markaId || 0;
    this.modelId = item.modelId || 0;
    this.musteriId = item.musteriId || 0;
    this.plaka = item.plaka || '';
    this.renkId = item.renkId || 0;
    this.job = item.job || '';
    this.activeJob = item.activeJob || false;
  }
}

export class DeleteVehicleModel {
  public plaka: string;
  public musteriId: number;

  constructor(item: any) {
    this.plaka = item.plaka || '';
    this.musteriId = item.musteriId || 0;
  }
}

export class VehicleModels {
  public modelId: string;
  public name: string;
  public orderNo: number;
  public aracMarkaId: number;
  public aracTipId: number;

  constructor(item: any) {
    this.modelId = item.modelId || '';
    this.name = item.name || '';
    this.orderNo = item.orderNo || 0;
    this.aracMarkaId = item.aracMarkaId || 0;
    this.aracTipId = item.aracTipId || 0;
  }
}

export class VehicleBrands {
  public brandId: string;
  public name: string;
  public logo: string;
  public orderNo: number;

  constructor(item: any) {
    this.brandId = item.brandId || '';
    this.name = item.name || '';
    this.logo = item.logo || '';
    this.orderNo = item.orderNo || 0;
  }
}

export class VehicleColors {
  public colorId: string;
  public name: string;
  public nameEn: string;
  public code: string;

  constructor(item: any) {
    this.colorId = item.colorId || '';
    this.name = item.name || '';
    this.nameEn = item.nameEn || '';
    this.code = item.code || '';
  }
}

export class VehicleModel {
  public plaka: string;
  public markaid: string;
  public modelid: string;
  public renkid: string;
  public ad: string | undefined;
  public soyad: string | undefined;
  public eposta: string | undefined;
  public telefon: string;
  public id: number;

  constructor(item: any) {
    this.plaka = item.plaka || '';
    this.markaid = item.markaid || '';
    this.modelid = item.modelid || '';
    this.renkid = item.renkid || '';
    this.ad = item.ad || '';
    this.soyad = item.soyad || '';
    this.eposta = item.eposta || '';
    this.telefon = item.telefon || '';
    this.id = item.id || 0;
  }
}
